import crudGetters from "@/store/templates/crud/getters";

export default {
  ...crudGetters,
  id: state => state.model.id,
  draftedAt: state => state.model.draftedAt,
  items: state => state.model.items,
  itemAmount: state => index => state.model.items[index].amount,
  tempItem: state => state.tempItem,
  subtotal: state =>
    state.model.items.reduce(
      (t, { quantity, unitPrice }) => t + quantity * unitPrice,
      0
    ),
  vatRate: state => state.model.vatRate,
  state: state => state.model.state,
  finalizedAt: state => state.model.finalizedAt,
  pdfDownloadable: state => state.model.pdfDownloadable,
  itemTotalAmount: state => index => {
    return (
      state.model.items[index].quantity * state.model.items[index].unitPrice
    );
  },
  draftedByFullName: state =>
    state.employee[state.user[state.model.draftedBy]?.employee]?.fullName,
  finalizedByFullName: state =>
    state.employee[state.user[state.model.finalizedBy]?.employee]?.fullName
};
